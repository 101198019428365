import Axios from 'axios'
// 第三方登录及授权接口  同一个接口 只是传参不一样
export function thirdPartyLoginAuth(data) {
    return Axios.post(`${window.Glod['ccex-apitsf']}/services/oauth2/authorize`,data )
}

// service/oauth2/logout 注销
// export function logout(orgid,data) {
//     return Axios.get(`${window.Glod['ccex-apitsf']}/services/oauth2/logout?${orgid}`,data )
// }

export function logout(orgid,data,config) {
    return Axios.post(`${window.Glod['ccex-apitsf']}/api/user/logout`,data,config)
}

// /services/oauth2/linkAuthorize  验证提供商授权
export function linkAuthorize(data,orgid,config) {
    return Axios.post(`${window.Glod['ccex-apitsf']}/services/oauth2/linkAuthorize?${orgid}`,data,config )
}