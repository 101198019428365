<template>
    <div></div>
</template>

<script>
import * as logoutApi from "./api"
// 单点注销
export default {
    name: "singleLogout",
    data() {
        return {
        //
        userLogin: true,
        };
    },
    mounted() {
        // 隐藏加载框
        document.getElementById("lightning-loading").style.display = "none";
        /**
         * 单点注销的话 
         * 判断下如果有登陆用户就调用注销接口，然后跳转登陆页面
         * 后跳转登陆页面
         * 没有直接跳转登陆页面
         */
        if(this.$CCDK.CCToken.getToken()){
            //有登陆用户
            // 注销 跳登陆
            const config = {
                headers:{
                    accessToken: this.$CCDK.CCToken.getToken(),
                }
            };
            let orgid=`orgId=${this.$store.state.userInfoObj.orgId}`
            logoutApi.logout(orgid,{},config).then((res)=>{
                //清除token
                this.$CCDK.CCToken.clearToken()
                window.location.replace(res.data.data)
            })
        }else{
            //跳登陆
            this.$router.push({
                    path: "/singleLogoutLogin",
            });
        }

    },
    methods:{
    }
}
</script>

<style scoped>

</style>
